const server = process.env.SERVER || "https://msc-ar.com";
const showMSC = process.env.SHOW_MSC || "visible";

export function setupScene(data) {
  // 建立 a-scene
  var aScene = $("<a-scene>").attr({
    ...(data.scene || {}),
    "color-space": "sRGB",
    renderer: "colorManagement: true, physicallyCorrectLights",
    "vr-mode-ui": "enabled: false",
    "device-orientation-permission-ui": "enabled: false"
  });

  // 建立 a-assets
  var aAssets = $("<a-assets>");
  data.assets.forEach(asset => {
    if (asset.type === "img") {
      var img = $("<img>").attr({
        id: asset.id,
        src: asset.src,
        crossorigin: "anonymous"
      });
      aAssets.append(img);
    } else if (asset.type === "item") {
      var aAssetItem = $("<a-asset-item>").attr(asset);
      aAssets.append(aAssetItem);
    } else if (asset.type === "video") {
      var video = $("<video>").attr(asset);
      aAssets.append(video);
    } else if (asset.type === "audio") {
      var audio = $("<audio>").attr(asset);
      aAssets.append(audio);
    }
  });

  // 將 a-assets 加入 a-scene
  aScene.append(aAssets);

  // 建立 a-camera
  var aCamera = $("<a-camera>").attr({
    position: "0 0 0",
    "look-controls": "enabled: false"
  });

  // 將 a-camera 加入 a-scene
  aScene.append(aCamera);

  // 建立 a-entity
  data.entities.forEach(entity => {
    var aEntity = $("<a-entity>");

    if (entity["gps-target"]) {
      aEntity.attr({
        "gps-target": entity["gps-target"]
      });
    }

    if (entity["question-option"]) {
      aEntity.attr({
        "question-option": entity["question-option"]
      });
    }

    if (entity["link-modal"]) {
      aEntity.attr({
        "link-modal": entity["link-modal"]
      });
    }

    if (entity["mindar-image-target"]) {
      aEntity.attr({
        "mindar-image-target": entity["mindar-image-target"]
      });

      // detect target found
      aEntity[0].addEventListener("targetFound", event => {
        console.log("target found");
        if (entity.children) {
          entity.children.forEach(child => {
            if (child.type === "video") {
              const $el = $(child.src);
              $el[0].play();
              // $el[0].loop = true;
            } else if (child.type === "sound") {
              const $el = $(child.src);
              $el[0].play();
              // $el[0].loop = true;
            }
          });
        }
      });
      // detect target lost
      aEntity[0].addEventListener("targetLost", event => {
        console.log("target lost");
        if (entity.children) {
          entity.children.forEach(child => {
            if (child.type === "video") {
              const $el = $(child.src);
              $el[0].pause();
            } else if (child.type === "sound") {
              const $el = $(child.src);
              $el[0].pause();
            }
          });
        }
      });
    }

    if (entity.children) {
      entity.children.forEach(child => {
        if (child.type === "gltf-model") {
          var aGltfModel = $("<a-gltf-model>").attr(child);
          aEntity.append(aGltfModel);
        } else if (child.type === "fbx-model") {
          const { src, type, ...other } = child;
          var aFbxModel = $("<a-entity>").attr({
            "fbx-model": src,
            ...(other || {})
          });
          aEntity.append(aFbxModel);
        } else if (child.type === "plane") {
          var aPlane = $("<a-plane>").attr(child);
          aEntity.append(aPlane);
        } else if (child.type === "image") {
          var aImage = $("<a-image>").attr(child);
          aEntity.append(aImage);
        } else if (child.type === "video") {
          var aVideo = $("<a-video>").attr(child);
          aEntity.append(aVideo);
        } else if (child.type === "sound") {
          var aSound = $("<a-sound>").attr(child);
          aEntity.append(aSound);
        }
      });
    }
    aScene.append(aEntity);
  });

  // 將 a-scene 加入 body
  $("body").append(aScene);

  const watermark = $(`
    <div id="watermark" class="absolute bottom-3 w-full z-5 flex justifi-center">
      <img src="/static/watermark.png" class="w-1/3 mx-auto"></img>
    </div>
  `);
  // 將 watermark 加入 body
  if (showMSC !== "hidden") {
    $("body").append(watermark);
  }

  var logo = $("<img>").attr({
    id: "menu-image",
    class:
      "absolute left-3 top-3 inline-block h-10 w-10 z-10 rounded-full cursor-pointer",
    src: "/static/logo.png"
  });
  // 將 logo 加入 body
  if (showMSC !== "hidden") {
    $("body").append(logo);
  }

  // $("#menu-image").on("click", function() {
  //   window.location.replace(server);
  // });

  const soundOnButton = $(`
    <div id="sound-on-btn" class="absolute right-3 top-3 px-1 py-1 inline-block h-10 w-10 z-10 rounded-full cursor-pointer bg-white">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
      </svg>
    </div>
  `);
  const soundoOffButton = $(`
    <div id="sound-off-btn" class="absolute right-3 top-3 px-1 py-1 inline-block h-10 w-10 z-10 rounded-full cursor-pointer bg-white">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
      </svg>
    </div>
  `);
  // 將 logo 加入 body
  $("body").append(soundOnButton);
  $("body").append(soundoOffButton);
  $("#sound-off-btn").hide();

  $("#sound-on-btn").on("click", function() {
    console.log("sound on");
    $("video").prop("muted", false);
    $("audio").prop("muted", false);

    $("#sound-on-btn").hide();
    $("#sound-off-btn").show();
  });

  $("#sound-off-btn").on("click", function() {
    console.log("sound off");

    $("video").prop("muted", true);
    $("audio").prop("muted", true);

    $("#sound-on-btn").show();
    $("#sound-off-btn").hide();
  });
}
