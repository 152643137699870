import { FBXLoader } from "./FBXLoader.js";
AFRAME.THREE.FBXLoader = FBXLoader;

/**
 * fbx-model
 *
 * Loader for FBX format.
 */
AFRAME.registerComponent("fbx-model", {
  schema: {
    src: { type: "asset" },
    crossorigin: { default: "" }
  },

  init: function() {
    this.model = null;
  },

  update: function() {
    const data = this.data;
    if (!data.src) return;

    this.remove();
    const loader = new AFRAME.THREE.FBXLoader();
    if (data.crossorigin) loader.setCrossOrigin(data.crossorigin);
    loader.load(data.src, this.load.bind(this));
  },

  load: function(model) {
    this.model = model;
    this.el.setObject3D("mesh", model);
    this.el.emit("model-loaded", { format: "fbx", model: model });
  },

  remove: function() {
    if (this.model) this.el.removeObject3D("mesh");
  }
});
