const server = process.env.SERVER || "https://msc-ar.com";

AFRAME.registerSystem("gps-system", {
  schema: {}, // System schema. Parses into `this.data`.

  watchId: null,

  init: function() {
    console.log("init gps system");
    this.entities = [];

    // 開啟 gps 權限
    if (!navigator.geolocation) {
      alert("瀏覽器不支援地理位置定位");
      return;
    }

    // 請求手機 GPS 權限
    navigator.geolocation.getCurrentPosition(
      position => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;

        // 處理獲取到的經緯度數據
        this.handleCoordinates(latitude, longitude);
      },
      function(error) {
        console.log("無法獲取位置資訊：" + error.message);
      }
    );

    // 監聽位置變化事件
    navigator.geolocation.watchPosition(
      position => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;

        // 處理獲取到的經緯度數據
        this.handleCoordinates(latitude, longitude);
      },
      function(error) {
        console.log("無法獲取位置資訊：" + error.message);
      }
    );
  },

  registerMe: function(el) {
    this.entities.push(el);
  },

  unregisterMe: function(el) {
    var index = this.entities.indexOf(el);
    this.entities.splice(index, 1);
  },

  handleCoordinates: function(latitude, longitude) {
    // 在這裡處理獲取到的經緯度數據
    console.log("current lat:" + latitude);
    console.log("current long:" + longitude);
    this.lat = latitude;
    this.long = longitude;
    window.entities = this.entities;
    this.entities.forEach(entity => {
      const { lat, long } = entity.components["gps-target"].data;
      const distance = this.calculateDistance(latitude, longitude, lat, long);

      if (distance < 10) {
        if (!entity.object3D.visible) {
          entity.object3D.visible = true;
          entity.getChildEntities().forEach(child => {
            if (child.getAttribute("type") === "video") {
              const $el = $(child.getAttribute("src"));
              $el[0].play();
              // $el[0].loop = true;
            } else if (child.getAttribute("type") === "sound") {
              const $el = $(child.getAttribute("src"));
              $el[0].play();
              // $el[0].loop = true;
            }
          });
        }
      } else {
        if (entity.object3D.visible) {
          entity.object3D.visible = false;
          entity.getChildEntities().forEach(child => {
            if (child.getAttribute("type") === "video") {
              const $el = $(child.getAttribute("src"));
              $el[0].pause();
            } else if (child.getAttribute("type") === "sound") {
              const $el = $(child.getAttribute("src"));
              $el[0].pause();
            }
          });
        }
      }
    });
  },

  calculateDistance: (lat1, lon1, lat2, lon2) => {
    // 使用 Haversine 公式计算两个坐标之间的距离
    const R = 6371; // 地球半径（单位：千米）
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // 距离（单位：千米）

    return distance * 1000; // 转换为米
  }
});

AFRAME.registerComponent("gps-target", {
  dependencies: ["gps-system"],

  schema: {
    lat: { type: "number" },
    long: { type: "number" }
  },

  init: function() {
    this.system = this.el.sceneEl.systems["gps-system"];
    this.system.registerMe(this.el);

    this.el.object3D.visible = false;
  },

  remove: function() {
    this.system.unregisterMe(this.el);
  }
});

AFRAME.registerSystem("question-system", {
  init: function() {},

  open: false,

  selectedOption: null,

  isFinished: false,
  finalOption: null,

  question: null,

  select: function(option) {
    this.selectedOption = option;
  },

  confirm: async function(selected, showAnswer, answer) {
    if (selected) {
      this.isFinished = true;
      this.finalOption = selected;
      $("#question-options-modal").hide();
      $("#question-title-text").text("");
      $("#question-modal").hide();
      $("#question-text").text("");
      $("#question-finish-modal").show();
      $("#question-finish-text").text(`你選擇了「${selected}」`);

      if (showAnswer === "true") {
        $("#question-show-answer").text(`正確答案是「${answer}」`);
        $("#question-show-answer").show();
      } else {
        $("#question-show-answer").hide();
      }

      let time = 3000;
      let timerIterval = null;
      const gap = 50;
      timerIterval = setInterval(() => {
        if (time > 0) {
          time -= gap;
          const style = `width: ${parseInt((100 * time) / 3000)}%`;
          $("#progress").attr("style", style);
        }
      }, gap);

      setTimeout(() => {
        if (!!timerIterval) {
          clearInterval(timerIterval);
          timerIterval = null;
        }

        $("#question-finish-modal").hide();
        this.isFinished = false;
        this.finalOption = null;
      }, 3000);

      // 一些 api 操作, 可以用 this.finalOption 和 this.question
      // ...

      try {
        const url = `${server}/api/response/create`;
        // const url = "https://msc-ar.com/api/response/create";
        // const url = "http://localhost:3000/api/response/create";
        const resourceId = this.selectedOption.id;
        const content = selected;
        const body = { resourceId, content };

        const result = await fetch(url, {
          body: JSON.stringify(body),
          mode: "cors",
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          }
        });
        console.log(result);
      } catch (error) {
        console.error(error);
      }
    }
  }
});

AFRAME.registerComponent("question", {
  dependencies: ["question-system"],

  schema: {
    id: { type: "string" },
    text: { type: "string" }
  },

  init: function() {
    this.system = this.el.sceneEl.systems["question-system"];
    this.system.question = this.data;
    this.system.open = true;

    if (this.system.open) {
      $("#question-text").text(this.data.text);
      $("#question-modal").show();
    }
    $("#question-close-btn").on("click", () => {
      $("#question-modal").hide();
      this.system.open = false;
    });
  }
});

AFRAME.registerComponent("question-option", {
  dependencies: ["question-system"],

  schema: {
    id: { type: "string" },
    text: { type: "string" },
    options: { type: "array" }
  },

  init: function() {
    this.system = this.el.sceneEl.systems["question-system"];
    window.system = this.system;

    var dot = $("<a-circle>").attr({
      position: "0 0 0",
      radius: "0.04",
      color: "#FF0000"
    });
    $(this.el).append(dot);

    // detect target found
    this.el.addEventListener("targetFound", event => {
      if (!this.system.open && !this.system.isFinished) {
        $("#question-options-modal").show();
        $("#question-title-text").text(this.data.text);
        $("#question-option-confirm-btn-1").text(this.data.options[0] || "");
        $("#question-option-confirm-btn-2").text(this.data.options[1] || "");
        $("#question-option-confirm-btn-3").text(this.data.options[2] || "");
        $("#question-option-confirm-btn-4").text(this.data.options[3] || "");

        $("#question-option-confirm-btn-1").on("click", () => {
          this.system.confirm(
            this.data.options[0] || "",
            this.data.showAnswer,
            this.data.answer
          );
        });
        $("#question-option-confirm-btn-2").on("click", () => {
          this.system.confirm(
            this.data.options[1] || "",
            this.data.showAnswer,
            this.data.answer
          );
        });
        $("#question-option-confirm-btn-3").on("click", () => {
          this.system.confirm(
            this.data.options[2] || "",
            this.data.showAnswer,
            this.data.answer
          );
        });
        $("#question-option-confirm-btn-4").on("click", () => {
          this.system.confirm(
            this.data.options[3] || "",
            this.data.showAnswer,
            this.data.answer
          );
        });

        this.system.select({
          id: this.data.id,
          text: this.data.text
        });
      }
    });
    // detect target lost
    this.el.addEventListener("targetLost", event => {
      $("#question-options-modal").hide();
      $("#question-title-text").text("");
      this.system.select(null);
    });
  }
});

AFRAME.registerComponent("link-modal", {
  schema: {
    title: { type: "string" },
    url: { type: "string" }
  },

  init: function() {
    var dot = $("<a-circle>").attr({
      position: "0 0 0",
      radius: "0.04",
      color: "#00FF00"
    });
    $(this.el).append(dot);

    // detect target found
    this.el.addEventListener("targetFound", event => {
      $("#link-modal").show();
      $("#link-title").text(this.data.title);
      $("#link-btn").attr("href", this.data.url);
    });
    // detect target lost
    this.el.addEventListener("targetLost", event => {
      $("#link-modal").hide();
      $("#link-title").text("");
      $("#link-btn").attr("href", "");
    });
  }
});
